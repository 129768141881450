import axios from 'axios'
import NProgress from 'nprogress'

const BASE_URL = 'http://api.herokey.top'

const service = axios.create({
  baseURL: BASE_URL,
  timeout: 5000
})

service.interceptors.request.use(config => {
  NProgress.start()
  return config
})
// 响应拦截
service.interceptors.response.use(res => {
  NProgress.done()
  return res
})

export default service
