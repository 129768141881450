// 全局css资源
import '@/assets/css/animation.css'
import '@/assets/css/icofont.css'
import '@/assets/css/pagination.css'
import '@/assets/css/reset.css'
import utils from '@/lib/utils'
// 网络请求加载提示
// import 'nprogress/nprogress.css'
// import './plugins/element.js'
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.mixin(utils)
Vue.config.productionTip = false
Vue.use(VueLazyload, {
  loading: require('@/assets/img/loading.png')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
