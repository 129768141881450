import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import(/* WebpackChunkName: "home_index" */ '@/views/home/Home'),
    redirect: '/home/individuation',
    children: [
      {
        path: '/home/individuation',
        component: () => import(/* WebpackChunkName: "individuation_index" */ '@/views/individuation/Individuation')
      },
      {
        path: '/recommend',
        component: () => import(/* WebpackChunkName: "playlist_index" */ '@/views/recommend/Recommend')
      },
      {
        path: '/songs',
        component: () => import(/* WebpackChunkName: "songs_index" */ '@/views/songs/Songs')
      },
      {
        path: '/mv',
        component: () => import(/* WebpackChunkName: "mv_index" */ '@/views/mv/mv')
      },
      {
        path: '/leaderboard',
        component: () => import(/* WebpackChunkName: "leaderboard_index" */ '@/views/leaderboard/LeaderBoard')
      },
      {
        path: '/singer',
        component: () => import(/* WebpackChunkName: "singer_index" */ '@/views/singer/SingerIndex')
      },
      {
        path: '/playlist',
        component: () => import(/* WebpackChunkName: "playlist_index" */ '@/views/playlist/Playlist')
      },
      {
        path: '/mvdetail',
        component: () => import(/* WebpackChunkName: "mvdetail_index" */ '@/views/mvdetail/MvDetail')
      },
      {
        path: '/singerdetail',
        component: () => import(/* WebpackChunkName: "singerdetail_index" */ '@/views/singer/children/SingerDetail')
      },
      {
        path: '/album',
        component: () => import(/* WebpackChunkName: "album_index" */ '@/views/album/Album')
      },
      {
        path: '/result',
        component: () => import(/* WebpackChunkName: "album_index" */ '@/views/result/Result')
      }
    ]
  }

]

const router = new VueRouter({
  routes
})

export default router
