import { playMusicAPI } from '@/api/api'
export default {
  filters: {
    // 格式化播放时间
    formatDuration (duration) {
      // 分钟：向下取整，转为字符，不足两位前边补零
      const minute = Math.floor(duration / 1000 / 60)
        .toString()
        .padStart(2, '0')
      const second = Math.floor(duration % 60)
        .toString()
        .padStart(2, '0')
      return minute + ':' + second
    },
    // 底部音乐播放时间
    formatDt (duration) {
      const minute = Math.floor(duration / 60)
        .toString()
        .padStart(2, '0')
      const second = Math.floor(duration % 60)
        .toString()
        .padStart(2, '0')
      return minute + ':' + second
    },
    // 格式化创建者时间 2020/4/08
    LocaleDateString (date) {
      if (!date) return ''
      const createDate = new Date(date)
      return createDate.toLocaleDateString()
    },
    // 格式化评论时间  "2021/3/30下午11:08:51"
    LocaleString (dateTime) {
      if (!dateTime) return ''
      const newDateTime = new Date(dateTime)
      return newDateTime.toLocaleString()
    },
    // 省略播放量
    ellipsisPlayVolume (val) {
      // 大于十万的显示 xx万
      if (val < 100000) return val
      return parseInt(val / 10000) + '万'
    }
  },
  methods: {
    async playMusic (data, musicInfo) {
      const { data: res } = await playMusicAPI({ id: data.id })
      if (res.data[0].url) {
        const songUrl = res.data[0].url
        this.$store.commit('changeMusicUrl', songUrl)
        this.$store.commit('changeMusicInfo', musicInfo)
        this.$store.commit('changeMusicStatus', false)
        const ids = []
        for (const item of this.musicQueue) {
          ids.push(item.id)
        }
        this.$store.commit('changeNowIndex', ids.indexOf(musicInfo.id))
        this.$notify({
          title: `歌曲：${musicInfo.songName}`,
          type: 'success',
          dangerouslyUseHTMLString: true,
          message: '已添加到播放列表且开始播放',
          offset: 200
        })
      } else {
        this.$message.error({
          showClose: true,
          message: '对不起，歌曲暂时无法播放。',
          center: true
        })
      }
    }
  }
}
