<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
    play () {
      this.$notify({
        title: '单击即可播放音乐',
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: '<strong><i>不需要双击噢~</i></strong>',
        offset: 200
      })
    }
  },
  mounted () {
    if (document.documentElement.clientWidth > 600) {
      this.play()
    }
  }
}
</script>

<style>
@media screen and (max-width: 479px) {
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}
</style>
